import {
    Box,
    Center,
    Circle,
    HStack,
    Heading,
    Icon,
    SimpleGrid,
    Skeleton,
    Spinner,
    Stack,
    Text,
    VStack,
    useBreakpointValue,
} from "@chakra-ui/react";
import social_media from "../data/social_media.json";
import address from "../data/address.json";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { HiOutlineLocationMarker } from "react-icons/hi";
import FadeIn from "../components/Animation/FadeIn";
import { Link } from "react-router-dom";
import IF_SubMenu from "../components/IF_SubMenu";

function Local_social_media({ contact, index }) {
    const [isHover, setIsHover] = useState(false);
    const delay_animate = useBreakpointValue([0, index / 2]);

    return (
        <FadeIn delay={delay_animate} duration={1}>
            <Center h="100%">
                <Link href={contact.link}>
                    <Center
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        role="group"
                        gap={[0, 4]}
                        flexDir="column"
                    >
                        <motion.div
                            animate={{
                                rotate: isHover ? 0 : 360,
                                scale: isHover ? 1.2 : 1,
                            }}
                            transition={{
                                repeatType: "reverse",
                                ease: "easeInOut",
                                duration: 0.6,
                            }}
                        >
                            <Center w={{ base: "10vw", lg: "5vw", xl: "10vh" }}>
                                <img alt="" src={contact.icon} />
                            </Center>
                        </motion.div>
                        <Heading fontSize="3xl">{contact.text}</Heading>
                    </Center>
                </Link>
            </Center>
        </FadeIn>
    );
}

export default function Contact() {
    const [map_ready, set_map_ready] = useState(false);
    useEffect(() => {
        document.getElementById("map").onload = () => {
            set_map_ready(true);
        };
    }, []);

    return (
        <>
            <Center
                gap={8}
                py={{ base: "10vh", xl: "0" }}
                h={{ base: "auto", xl: "100vh" }}
                bg="url(/images/bg.jpg) no-repeat bottom"
                bgSize="cover"
                overflow="hidden"
                mt="0px !important"
                flexDir={{ base: "column", lg: "row" }}
            >
                <motion.div
                    initial={{
                        scale: 2,
                        opacity: 0,
                        filter: "blur(5px) grayScale(1)",
                    }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        filter: "blur(0px) grayScale(0)",
                    }}
                    transition={{ duration: 1, delay: 0.2 }}
                >
                    <Center pos="relative">
                        <Circle
                            zIndex={1}
                            w={{ base: "50vw", md: "30vw", xl: "20vw" }}
                            p="5vh"
                            overflow="hidden"
                            bgColor="white"
                        >
                            <img src="/logo-crop.png" alt="" />
                        </Circle>
                    </Center>
                </motion.div>
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <Stack w={{ base: "80vw", xl: "50vw" }} gap={8}>
                        <Box>
                            <IF_SubMenu text1="" text2="business hours" />
                            <Text fontSize="xl">
                                Monday - Friday 07:00am - 09:00pm
                            </Text>
                            <Text fontSize="xl">
                                Saturday - Sunday 08:00am - 06:00pm
                            </Text>
                        </Box>
                        <Box>
                            <IF_SubMenu text1="" text2="ADDRESS" />
                            <Stack gap={1}>
                                {address.map((a) => (
                                    <HStack key={a}>
                                        <Icon
                                            fontSize="xl"
                                            color="pink"
                                            as={HiOutlineLocationMarker}
                                        />
                                        <Text fontSize="xl">{a}</Text>
                                    </HStack>
                                ))}
                            </Stack>
                        </Box>
                    </Stack>
                </motion.div>
            </Center>
            <Box zIndex={0} p={10} py={{ xl: 20 }}>
                <SimpleGrid
                    templateColumns={{
                        base: "repeat(1,1fr)",
                        md: `repeat(${social_media.length / 2},1fr)`,
                        xl: `repeat(${social_media.length},1fr)`,
                    }}
                    gap={10}
                    justifyContent="space-evenly"
                    w="100%"
                    h="100%"
                    py={[10, 0]}
                    zIndex={1}
                >
                    {social_media.map((contact, index) => (
                        <Local_social_media
                            key={contact.link}
                            contact={contact}
                            index={index}
                        />
                    ))}
                </SimpleGrid>
            </Box>
            <Center w="100%" h="70vh" pos="relative">
                <VStack
                    display={map_ready ? "none" : "flex"}
                    pos="absolute"
                    top="50%"
                    left="50%"
                    translate="-50% -50%"
                >
                    <Spinner color="pink" size="xl" thickness="5px" />
                    <Heading color="pink" fontSize="md">
                        Loading Map
                    </Heading>
                </VStack>
                <Skeleton w="100%" h="full" isLoaded={map_ready}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        id="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.7310011533937!2d106.81141387469609!3d-6.166765993820523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f7d5b46a3391%3A0xddcd712d780158a!2sI-FIT%20INDONESIA!5e0!3m2!1sen!2sid!4v1683534573791!5m2!1sen!2sid"
                        allowFullScreen={true}
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Skeleton>
            </Center>
        </>
    );
}
