import { useBreakpointValue } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function FadeIn({
    children,
    duration = 1,
    delay = 0,
    amount = 0.2,
    init_y,
}) {
    const default_init_y = useBreakpointValue({
        base: "50%",
        lg: "100%",
    });
    const y = init_y ? init_y : default_init_y;

    return (
        <motion.div
            initial={{
                opacity: 0,
                y: y,
            }}
            whileInView={{
                opacity: 1,
                y: "0%",
            }}
            transition={{
                duration: duration,
                delay: delay,
            }}
            viewport={{
                once: true,
                amount: amount,
            }}
            style={{
                height: "100%",
                width: "100%",
                position: "relative",
            }}
        >
            {children}
        </motion.div>
    );
}
