import {
    Box,
    Heading,
    Stack,
    Text,
    VStack,
    Center,
    Circle,
    HStack,
    Icon,
    useBreakpointValue,
    AspectRatio,
} from "@chakra-ui/react";
import { CiLocationArrow1 } from "react-icons/ci";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import FadeIn from "../components/Animation/FadeIn";
import IF_Button from "../components/IF_Button";
import IF_SubMenu from "../components/IF_SubMenu";
import IF_Heading from "../components/IF_Heading";
import IF_Swiper from "../components/IF_Swiper";

import services from "../data/services.json";
import { getFacilities } from "../api/facilities";

export default function Home() {

    const minus_delay = useBreakpointValue({
        base: services.length * 0.3,
        lg: 0,
    });
    const [facilitiesFile, set_facilitiesFile] = useState([]);
    useEffect(() => {
        getFacilities().then((res) => {
            set_facilitiesFile(res);
        });
    }, []);

    return (
        <Stack mt="0px !important">
            <Center
                w="100%"
                py={{ base: "12vh", xl: "0" }}
                h={{ base: "auto", xl: "100vh" }}
                overflow="hidden"
                pos="relative"
            >
                <motion.div
                    animate={{
                        opacity: [0, 1],
                    }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        background: "url('/images/bg.jpg') no-repeat bottom",
                        bgSize: "cover",
                    }}
                ></motion.div>
                <Center
                    textAlign="center"
                    gap={{ base: 4, md: 8, lg: 4 }}
                    w={{ base: "90%", xl: "80%" }}
                    flexDir="column"
                >
                    <motion.div
                        animate={{
                            scale: [1, 1.1],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 1,
                        }}
                    >
                        <Circle
                            overflow="hidden"
                            p={6}
                            bgColor="whiteAlpha.900"
                            w={{ base: "45vw", md: "28vw", lg: "12vw" }}
                        >
                            <img src="/logo-crop.png" alt="" />
                        </Circle>
                    </motion.div>

                    <VStack zIndex={1}>
                        <motion.div
                            animate={{
                                scale: [3, 1],
                                opacity: [0, 1],
                            }}
                            transition={{
                                duration: 0.5,
                                delay: 4 - minus_delay,
                            }}
                        >
                            <Heading fontSize="5xl" color="pink">
                                I-FIT INDONESIA
                            </Heading>
                        </motion.div>
                        <HStack>
                            <motion.div
                                animate={{
                                    x: ["-100%", "0%"],
                                    opacity: [0, 1],
                                }}
                                transition={{
                                    duration: 0.3,
                                    delay: 3 - minus_delay,
                                }}
                            >
                                <Heading
                                    textAlign="center"
                                    whiteSpace="nowrap"
                                    fontSize={{ base: "xl", md: "3xl" }}
                                >
                                    SPORT THERAPY
                                </Heading>
                            </motion.div>
                            <motion.div
                                animate={{
                                    x: ["100%", "0%"],
                                    opacity: [0, 1],
                                }}
                                transition={{
                                    duration: 0.3,
                                    delay: 3.5 - minus_delay,
                                }}
                            >
                                <Heading
                                    whiteSpace="nowrap"
                                    textAlign="center"
                                    fontSize={{ base: "xl", md: "3xl" }}
                                >
                                    & FITNESS STUDIO
                                </Heading>
                            </motion.div>
                        </HStack>

                        <Stack pt={8}>
                            {[0, 1, 2].map((i) => (
                                <motion.div
                                    key={i}
                                    transition={{
                                        duration: 1,
                                        repeat: Infinity,
                                        repeatType: "loop",
                                        repeatDelay: 1 * i,
                                        delay: 5,
                                    }}
                                    animate={{
                                        opacity: [0, 1],
                                    }}
                                >
                                    <Icon
                                        mt={-5}
                                        fontSize={{ base: "4xl", lg: "5xl" }}
                                        transform="rotate(135deg)"
                                        color="pink"
                                        as={CiLocationArrow1}
                                    />
                                </motion.div>
                            ))}
                        </Stack>
                    </VStack>
                </Center>
            </Center>

            <Box role="group">
                <IF_Heading>ABOUT US</IF_Heading>

                <Center>
                    <VStack py={10} w="80%" textAlign="center">
                        <Circle bgColor="pink" h={40} w={40} pos="relative">
                            <Center
                                rounded="full"
                                overflow="hidden"
                                bottom="0"
                                left="50%"
                                transform="translateX(-50%)"
                                pos="absolute"
                                w="100%"
                            >
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{
                                        opacity: 1,
                                        x: ["-100%", "10%", "0%"],
                                    }}
                                    transition={{
                                        ease: "easeInOut",
                                        duration: 2,
                                    }}
                                    viewport={{
                                        once: true,
                                        amount: 1,
                                    }}
                                >
                                    <img alt="" src="/images/kinan-crop.png" />
                                </motion.div>
                            </Center>
                        </Circle>
                        <motion.div
                            initial={{ opacity: 0, scale: 3 }}
                            whileInView={{
                                scale: 1,
                                opacity: 1,
                            }}
                            viewport={{
                                once: true,
                            }}
                            transition={{ duration: 0.5 }}
                        >
                            <Heading color="pink">
                                R. RETNA KINANTI DEWI .M.Pd.AIFO.FIT
                            </Heading>
                        </motion.div>
                        <VStack gap={2}>
                            <FadeIn>
                                <Text fontSize="xl" display="inline-block">
                                    PT Duta Bugar Indonesia mendirikan studio
                                    I-FIT Indonesia pada Agustus 2016. Dipimpin
                                    oleh
                                </Text>
                                <Text
                                    display="inline-block"
                                    fontSize="2xl"
                                    px={1}
                                    fontWeight="bold"
                                    color="biruMuda"
                                    whiteSpace="nowrap"
                                >
                                    R Retna Kinanti Dewi.
                                </Text>
                                <Text
                                    fontSize="2xl"
                                    px={1}
                                    fontWeight="bold"
                                    color="biruMuda"
                                    display="inline-block"
                                    whiteSpace="nowrap"
                                >
                                    M.Pd.AIFO.FIT
                                </Text>
                                <Text fontSize="xl" display="inline-block">
                                    sebagai pemilik dan pendiri I-FIT Indonesia
                                    yang bersertifikat Postural Expert.
                                </Text>
                            </FadeIn>
                            <FadeIn>
                                <Text fontSize="xl">
                                    Perusahaan kami bergerak dibidang jasa
                                    kesehatan dan kebugaran yang berfokus kepada
                                    masalah postural tulang belakang. Selain
                                    itu, lingkup bisnis kami juga bergerak
                                    dibidang jasa gym management dan sertifikasi
                                    untuk pelatihan profesi personal trainer.
                                </Text>
                            </FadeIn>
                            <FadeIn>
                                <Text fontSize="xl">
                                    Tujuan dari perusahaan I-FIT Indonesia untuk
                                    menjadi satu-satunya pusat jasa kesehatan
                                    dan kebugaran di Indonesia yang memastikan
                                    bahwa keluhan postural tulang belakang bisa
                                    melalui program olahraga yang tepat
                                    (membantu pengurangan jumlah penderita pada
                                    keluhan postural tulang belakang)
                                </Text>
                            </FadeIn>
                        </VStack>
                    </VStack>
                </Center>
            </Box>

            <Box role="group">
                <IF_Heading>OUR SERVICES</IF_Heading>

                {services.map((s, index) => (
                    <Center
                        key={index}
                        py={4}
                        px={{ base: 8, lg: 0 }}
                        flexDir={[
                            "column",
                            index % 2 === 0 ? "row" : "row-reverse",
                        ]}
                        w="100%"
                        gap={[0, 8]}
                    >
                        <Stack>
                            <FadeIn
                                direction={index % 2 === 0 ? "right" : "left"}
                            >
                                <Center mb={{ base: 4, md: "0" }} w="100%">
                                    <AspectRatio
                                        w={{
                                            base: "80vw",
                                            md: "40vw",
                                            lg: "30vw",
                                        }}
                                        ratio={3 / 2}
                                    >
                                        <Box
                                            _hover={{
                                                _before: {
                                                    opacity: 1,
                                                    transform:
                                                        "skewX(-45deg) translateX(100vw)",
                                                },
                                                _after: {
                                                    opacity: 1,
                                                    transform:
                                                        "skewX(-45deg) translateX(-100vw)",
                                                },
                                            }}
                                            _before={{
                                                transition: "all 1s ease-in",
                                                h: "100%",
                                                content: '""',
                                                width: "30%",
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                                zIndex: 10,
                                                transform:
                                                    "skewX(-45deg) translateX(-300%)",
                                                bgGradient:
                                                    "linear(to-r, transparent, rgba(234, 42, 94,.7), transparent)",
                                            }}
                                            _after={{
                                                transition: "all 1s ease-in",
                                                h: "100%",
                                                content: '""',
                                                width: "30%",
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                                zIndex: 10,
                                                transform:
                                                    "skewX(-45deg) translateX(300%)",
                                                bgGradient:
                                                    "linear(to-r, transparent, rgba(255,255,255,.7), transparent)",
                                            }}
                                            overflow="hidden"
                                            h="100%"
                                            w="100%"
                                            rounded="xl"
                                            pos="relative"
                                        >
                                            <img
                                                alt=""
                                                style={{
                                                    zIndex: 1,
                                                    objectFit: "cover",
                                                }}
                                                src={s.img}
                                                loading="lazy"
                                            />
                                        </Box>
                                    </AspectRatio>
                                </Center>
                            </FadeIn>
                        </Stack>
                        <Stack w={{ base: "100%", lg: "40vw", lg: "50vw" }}>
                            <FadeIn
                                direction={index % 2 === 0 ? "left" : "right"}
                            >
                                <IF_SubMenu
                                    textAlign={
                                        index % 2 === 0 ? "left" : "right"
                                    }
                                    text1={s.title[0]}
                                    text2={s.title[1]}
                                />

                                <Stack
                                    textAlign={
                                        index % 2 === 0 ? "start" : "end"
                                    }
                                    gap={2}
                                >
                                    {s.desc.map((d, index) => (
                                        <Text fontSize="xl" key={index}>
                                            {d}
                                        </Text>
                                    ))}
                                    <Link href={s.link}>
                                        <Box>
                                            <IF_Button text="More Details" />
                                        </Box>
                                    </Link>
                                </Stack>
                            </FadeIn>
                        </Stack>
                    </Center>
                ))}
            </Box>

            <IF_Swiper data={facilitiesFile} />
        </Stack>
    );
}
